import * as React from 'react'
import Layout from '../components/layout'
import { Button, colors } from '../components/common/button'
import SEO from '../components/seo'

const NotFoundPage = ({ location }) => {
  return (
    <Layout template={'404'} location={location}>
      <SEO title={'Siden ble ikke funnet - 404'} />
      <div className={'bg-blue-lighter h-screen'}>
        <div className={'xl-container mx-auto h-screen'}>
          <div
            className={
              'grid grid-cols-1 md:grid-cols-12 items-end content-center justify-items-start h-full w-full gap-8'
            }
          >
            <div className={'p-0 m-0 md:col-span-4'}>
              <p
                className={
                  'font-medium text-blue-dark text-[61px] leading-[73px] lg:text-[80px] lg:leading-[96px]'
                }
              >
                404
              </p>
              <p className={'text-lg lg:text-xl text-blue-dark'}>
                Her har det skjedd en feil.
              </p>
            </div>
            <div
              className={
                'w-full md:w-max mb-4 col-span-1 md:col-end-9 lg:col-start-5'
              }
            >
              <div className={'grid grid-cols-2 gap-2  w-full md:w-max'}>
                <Button
                  color={colors.blue}
                  variant={'contained'}
                  path={'/'}
                  isExternal={false}
                  fullwidth={'true'}
                >
                  Gå til forsiden
                </Button>
                <Button
                  variant={'outlined'}
                  path={'/kontakt/'}
                  isExternal={false}
                  fullwidth={'true'}
                >
                  Kontakt oss
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
